import Konva from "konva";
import loadImage from "./imageShape";
import solguy from "../img/eyes/sol/body-bg.png";
import srmguy from "../img/eyes/srm/body-bg.png";
import zetaguy from "../img/eyes/zeta/body-bg.png";
import mngoguy from "../img/eyes/mngo/body-bg.png";
import rayguy from "../img/eyes/ray/body-bg.png";
import daosolguy from "../img/eyes/daosol/body-bg.png";


import soleye1 from "../img/eyes/sol/eye1.png";
import soleye2 from "../img/eyes/sol/eye2.png";
import soleye3 from "../img/eyes/sol/eye3.png";
import soleye4 from "../img/eyes/sol/eye4.png";
import soleye5 from "../img/eyes/sol/eye5.png";
import soleye6 from "../img/eyes/sol/eye6.png";

import srmeye1 from "../img/eyes/srm/eye1.png";
import srmeye2 from "../img/eyes/srm/eye2.png";
import srmeye3 from "../img/eyes/srm/eye3.png";
import srmeye4 from "../img/eyes/srm/eye4.png";
import srmeye5 from "../img/eyes/srm/eye5.png";
import srmeye6 from "../img/eyes/srm/eye6.png";

import zetaeye1 from "../img/eyes/zeta/eye1.png";
import zetaeye2 from "../img/eyes/zeta/eye2.png";
import zetaeye3 from "../img/eyes/zeta/eye3.png";
import zetaeye4 from "../img/eyes/zeta/eye4.png";
import zetaeye5 from "../img/eyes/zeta/eye5.png";
import zetaeye6 from "../img/eyes/zeta/eye6.png";

import rayeye1 from "../img/eyes/ray/eye1.png";
import rayeye2 from "../img/eyes/ray/eye2.png";
import rayeye3 from "../img/eyes/ray/eye3.png";
import rayeye4 from "../img/eyes/ray/eye4.png";
import rayeye5 from "../img/eyes/ray/eye5.png";
import rayeye6 from "../img/eyes/ray/eye6.png";


import mngoeye1 from "../img/eyes/mngo/eye1.png";
import mngoeye2 from "../img/eyes/mngo/eye2.png";
import mngoeye3 from "../img/eyes/mngo/eye3.png";
import mngoeye4 from "../img/eyes/mngo/eye4.png";
import mngoeye5 from "../img/eyes/mngo/eye5.png";
import mngoeye6 from "../img/eyes/mngo/eye6.png";


import daosoleye1 from "../img/eyes/daosol/eye1.png";
import daosoleye2 from "../img/eyes/daosol/eye2.png";
import daosoleye3 from "../img/eyes/daosol/eye3.png";
import daosoleye4 from "../img/eyes/daosol/eye4.png";
import daosoleye5 from "../img/eyes/daosol/eye5.png";
import daosoleye6 from "../img/eyes/daosol/eye6.png";


let bgArray = {
  sol:solguy,
  srm:srmguy,
  zeta:zetaguy,
  mngo: mngoguy,
  ray: rayguy,
  daosol: daosolguy
}
let coinArray = {
  sol:[{left: soleye1, right: soleye1}, {left: soleye2, right: soleye2}, {left: soleye3, right: soleye3}, {left: soleye4, right: soleye4}, {left: soleye5, right: soleye5}, {left: soleye6, right: soleye6}],
  srm:[{left: srmeye1, right: srmeye1}, {left: srmeye2, right: srmeye2}, {left: srmeye3, right: srmeye3}, {left: srmeye4, right: srmeye4}, {left: srmeye5, right: srmeye5}, {left: srmeye6, right: srmeye6}],
  zeta:[{left: zetaeye1, right: zetaeye1}, {left: zetaeye2, right: zetaeye2}, {left: zetaeye3, right: zetaeye3}, {left: zetaeye4, right: zetaeye4}, {left: zetaeye5, right: zetaeye5}, {left: zetaeye6, right: zetaeye6}],
  ray:[{left: rayeye1, right: rayeye1}, {left: rayeye2, right: rayeye2}, {left: rayeye3, right: rayeye3}, {left: rayeye4, right: rayeye4}, {left: rayeye5, right: rayeye5}, {left: rayeye6, right: rayeye6}],
  mngo:[{left: mngoeye1, right: mngoeye1}, {left: mngoeye2, right: mngoeye2}, {left: mngoeye3, right: mngoeye3}, {left: mngoeye4, right: mngoeye4}, {left: mngoeye5, right: mngoeye5}, {left: mngoeye6, right: mngoeye6}],
  daosol:[{left: daosoleye1, right: daosoleye1}, {left: daosoleye2, right: daosoleye2}, {left: daosoleye3, right: daosoleye3}, {left: daosoleye4, right: daosoleye4}, {left: daosoleye5, right: daosoleye5}, {left: daosoleye6, right: daosoleye6}]
  };

import {addCoins, removeCoins} from "./coins";
import {downloadURI} from "./export";
import * as face from './face';


let coinElements,
    otherCurrenciesContainer,
    moreButton,
    imageChanged = false,
    leftCoin,
    rightCoin,
    userImage,
    selectedCoin = soleye1,
    scaleLeft = 1.5,
    scaleRight = 1.5,
    rotationLeft = 0,
    rotationRight = 0,
    canvas,
    x = 280, y = 92, size = 60,
    x2 = 320, y2 = 88, size2 = 60;

let fileLabel = document.getElementById('fileLabel');

let mainWidth = Math.min(600, window.innerWidth - 60);
if(mainWidth < 600) {
  x = x * (mainWidth/600);
  x2 = x2 * (mainWidth/600);
  y = y * (mainWidth/600);
  y2 = y2 * (mainWidth/600);
}

// first we need to create a stage
var stage = new Konva.Stage({
  container: 'container',   // id of container <div>
  width: mainWidth,
  height: mainWidth
});

/**
 *
 * @type {Konva.Layer}
 */
var layer = new Konva.Layer();

// add the layer to the stage
stage.add(layer);

// draw the image
layer.draw();

document.getElementById("file").addEventListener("change", (event) => {
  if(event.target.files.length > 0){
    imageChanged = true;

    let src = URL.createObjectURL(event.target.files[0]);
    removeCoins();
    fileLabel.style.position = "static";
    fileLabel.style.top = "0";
    fileLabel.style.left = "0";
    loadImage(src, false, mainWidth, mainWidth, 0, 0, false, true).then(
      ({ image, width, height}) => {


        if (userImage) {
          userImage.destroy();
        }
        userImage = image;
        stage.width(width);
        stage.height(height);
        layer.add(image);

        face.detect(stage.toCanvas()).then(({ leftEye, rightEye }) => {
          x = leftEye.x;
          y = leftEye.y;
          //size = leftEye.size;

          x2 = rightEye.x;
          y2 = rightEye.y;
          //size2 = rightEye.size;
          removeCoins(leftCoin, rightCoin);
          addCoins(selectedCoin, layer, x, y, 60, x2, y2, 60).then(
            (coins) => {
              leftCoin = coins[0];
              rightCoin = coins[1];


              leftCoin.scale({
                x: scaleLeft / (600/canvas.offsetWidth),
                y: scaleLeft / (600/canvas.offsetWidth),
              });
              rightCoin.scale({
                x: scaleRight / (600/canvas.offsetWidth),
                y: scaleRight / (600/canvas.offsetWidth),
              });
              rotationLeft = 0;
              rotationRight = 0;
              leftCoin.rotate(rotationLeft);
              rightCoin.rotate(rotationRight);
            }
          );
        })
        .catch(() => {
          x = 280;
          y = 92;
          //size = 60;

          x2 = 320;
          y2 = 88;
          //size2 = 60;
          removeCoins(leftCoin, rightCoin);
          addCoins(selectedCoin, layer, x, y, 60, x2, y2, 60).then(
            (coins) => {

              leftCoin = coins[0];
              rightCoin = coins[1];

              leftCoin.scale({ x: scaleLeft / (600/canvas.offsetWidth), y: scaleLeft / (600/canvas.offsetWidth)});
              rightCoin.scale({ x: scaleRight / (600/canvas.offsetWidth), y: scaleRight / (600/canvas.offsetWidth)});
              leftCoin.rotate(rotationLeft);
              rightCoin.rotate(rotationRight);
            }
          );
        });

        document.getElementById('save').classList.add('active');
        document.getElementById('fileLabel').classList.remove('active');
      }
    );
  }

});

function addListeners() {


  canvas = document.querySelector('#container canvas');

  coinElements = document.querySelectorAll('.coins');
  let coins = document.querySelectorAll('.coins .coin');
  for(let i=0; i<coins.length; i++) {

    coins[i].addEventListener("click", (ev) => {

        removeCoins(leftCoin, rightCoin);
        leftCoin = null;
        rightCoin = null;

        selectedCoin = coinArray[ev.target.getAttribute('cur')][ev.target.getAttribute('index')];

        addCoins(selectedCoin, layer, x, y, 60, x2, y2, 60).then(onCoinAdded);
    });
  }

  var scaleSlider = document.getElementById("scaleSlider");
  var scaleLeftSlider = document.getElementById("scaleLeftSlider");
  var scaleRightSlider = document.getElementById("scaleRightSlider");
  var rotationSlider = document.getElementById("rotationSlider");
  var rotationLeftSlider = document.getElementById("rotationLeftSlider");
  var rotationRightSlider = document.getElementById("rotationRightSlider");


  scaleSlider.ondblclick = function() {
    scaleSlider.style.display = "none"
    scaleLeftSlider.style.display = "block"
    scaleRightSlider.style.display = "block"
  };

  scaleLeftSlider.ondblclick = function() {
    scaleSlider.style.display = "block"
    scaleLeftSlider.style.display = "none"
    scaleRightSlider.style.display = "none"
  };

  scaleRightSlider.ondblclick = function() {
    scaleSlider.style.display = "block"
    scaleLeftSlider.style.display = "none"
    scaleRightSlider.style.display = "none"
  };

  scaleSlider.oninput = function() {

    scaleRight = (this.value/100)+0.5;
    scaleLeft = (this.value/100)+0.5;

    leftCoin.scale({x:scaleLeft / (600/canvas.offsetWidth), y:scaleLeft / (600/canvas.offsetWidth)});
    rightCoin.scale({x:scaleRight / (600/canvas.offsetWidth), y:scaleRight / (600/canvas.offsetWidth)});

    scaleLeftSlider.value = this.value;
    scaleRightSlider.value = this.value;
  }

  scaleLeftSlider.oninput = function() {


    scaleLeft = (this.value/100)+0.5;

    leftCoin.scale({x:scaleLeft / (600/canvas.offsetWidth), y:scaleLeft / (600/canvas.offsetWidth)});

    scaleSlider.value = this.value;
  }

  scaleRightSlider.oninput = function() {
    scaleRight = (this.value/100)+0.5;
    rightCoin.scale({x:scaleRight / (600/canvas.offsetWidth), y:scaleRight / (600/canvas.offsetWidth)});

    scaleSlider.value = this.value;
  }

  rotationSlider.ondblclick = function() {
    rotationSlider.style.display = "none"
    rotationLeftSlider.style.display = "block"
    rotationRightSlider.style.display = "block"
  };

  rotationLeftSlider.ondblclick = function() {
    rotationSlider.style.display = "block"
    rotationLeftSlider.style.display = "none"
    rotationRightSlider.style.display = "none"
  };

  rotationRightSlider.ondblclick = function() {
    rotationSlider.style.display = "block"
    rotationLeftSlider.style.display = "none"
    rotationRightSlider.style.display = "none"
  };

  rotationSlider.oninput = function() {
    rotationLeft = rotationRight = this.value;
    leftCoin.rotation(rotationLeft);
    rightCoin.rotation(rotationRight);

    rotationLeftSlider.value = this.value;
    rotationRightSlider.value = this.value;
  }

  rotationLeftSlider.oninput = function() {
    rotationLeft = this.value;
    leftCoin.rotation(rotationLeft);

    rotationSlider.value = this.value;
  }

  rotationRightSlider.oninput = function() {
    rotationRight = this.value;
    rightCoin.rotation(rotationRight);

    rotationSlider.value = this.value;
  }

  document.getElementById('save').addEventListener(
    'click',
    function () {
      var dataURL = stage.toDataURL({ pixelRatio: 3 });
      downloadURI(dataURL, 'coinEyes.png');
    },
    false
  );

  window.addEventListener('load', function () {
    face.FaceApiFactory.getInstance();
  });

  window.addEventListener('hashchange', function(ev) {
      changeCurrency(document.location.hash.replace('#', ''));
      window.ga('event', 'click', {
        'event_category':'Currencies',
        'event_action':'Change',
        'event_label': location.hash.replace('#','')
      });
  });
  otherCurrenciesContainer = document.getElementById('otherCurrencies');
  moreButton = document.getElementById('more');
  more.addEventListener('click', function () {
    otherCurrenciesContainer.style.display = 'flex';
    moreButton.style.visibility = 'hidden';
  });

}

addListeners();


changeCurrency(document.location.hash.replace('#', ''));




function changeCurrency(currency) {

  if(currency) {
    let other = document.querySelector('#otherCurrencies #' + currency);
    if(other) {
      otherCurrenciesContainer.style.display = 'flex';
      moreButton.style.visibility = 'hidden';
    }
  }


  for (let i = 0; i < coinElements.length; i++) {
    coinElements[i].classList.remove('selected');
  }

  let selectedCurrency = document.querySelector("." + currency + '-coins');
  if (selectedCurrency) {
    selectedCurrency.classList.add('selected');
  } else {
    window.document.location.hash = "#sol";
    return;
  }
  if (coinArray[currency][2]) {
    selectedCoin = coinArray[currency][2];
  } else {
    selectedCoin = coinArray[currency][0];
  }



  if (!imageChanged) {

    loadImage(bgArray[currency], false, mainWidth, mainWidth, 0, 0, false, true).then(
      ({image, width, height}) => {


      if (userImage) {
        userImage.destroy();
      }
      userImage = image;
      layer.add(image);
      stage.width(width);
      stage.height(height);
      removeCoins(leftCoin, rightCoin);

      let leftX = selectedCoin.customPosition ? parseInt(canvas.offsetWidth * selectedCoin.customPosition.leftX) : x;
      let leftY = selectedCoin.customPosition ? parseInt(canvas.offsetHeight * selectedCoin.customPosition.leftY) : y;
      let rightX = selectedCoin.customPosition ? parseInt(canvas.offsetWidth * selectedCoin.customPosition.rightX) : x2;
      let rightY = selectedCoin.customPosition ? parseInt(canvas.offsetHeight * selectedCoin.customPosition.rightY) : y2;

      addCoins(selectedCoin, layer, leftX, leftY, 60, rightX, rightY, 60).then(onCoinAdded);
    });
  } else {


    let leftX = selectedCoin.customPosition ? parseInt(canvas.offsetWidth * selectedCoin.customPosition.leftX) : x;
    let leftY = selectedCoin.customPosition ? parseInt(canvas.offsetHeight * selectedCoin.customPosition.leftY) : y;
    let rightX = selectedCoin.customPosition ? parseInt(canvas.offsetWidth * selectedCoin.customPosition.rightX) : x2;
    let rightY = selectedCoin.customPosition ? parseInt(canvas.offsetHeight * selectedCoin.customPosition.rightY) : y2;

    removeCoins(leftCoin, rightCoin);
    addCoins(selectedCoin, layer, leftX, leftY, 60, rightX, rightY, 60).then(onCoinAdded);
  }

}

function onCoinAdded(coins) {
  leftCoin = coins[0];
  rightCoin = coins[1];

  leftCoin.scale({x:scaleLeft / (600/canvas.offsetWidth), y: scaleLeft / (600/canvas.offsetWidth)});
  rightCoin.scale({x:scaleRight / (600/canvas.offsetWidth), y: scaleLeft / (600/canvas.offsetWidth)});
  let leftRotation = selectedCoin.customPosition ? selectedCoin.customPosition.leftRotation : rotationLeft;
  let rightRotation = selectedCoin.customPosition ? selectedCoin.customPosition.rightRotation : rotationRight;
  leftCoin.rotate(leftRotation);
  rightCoin.rotate(rightRotation);
  leftCoin.on('dragend', function () {
    x = leftCoin.x();
    y = leftCoin.y();
  });
  rightCoin.on('dragend', function () {
    x2 = rightCoin.x();
    y2 = rightCoin.y();
  });
}
